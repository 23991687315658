<template>
  <div>
   <div class="container commonDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Mine/MoneyBag',query:{'tabIdx':'tab2'} }">提现记录</el-breadcrumb-item>
      <el-breadcrumb-item>提现详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="commonWhiteBg">
        <div class="commonTitle"> 提现详情 </div>
          <el-form
          ref="form"
          :model="form"
          label-width="140px"
        >
          <el-form-item
            label="订单编号"
            prop="value1"
          >
            {{form.value1}}
          </el-form-item>
           <el-form-item
            label="提现收款银行账户"
            prop="value2"
          >
            {{form.value2}}
          </el-form-item>
           <el-form-item
            label="提现银行"
            prop="value3"
          >
            {{form.value3}}
          </el-form-item>
           <el-form-item
            label="收款人"
            prop="value4"
          >
            {{form.value4}}
          </el-form-item>
           <el-form-item
            label="提现金额"
            prop="value5"
          >
            {{form.value5}}
          </el-form-item>
           <el-form-item
            label="创建时间"
            prop="value6"
          >
            {{form.value6}}
          </el-form-item>
           <el-form-item
            label="当前状态"
            prop="value7"
          >
            {{form.value7}}
          </el-form-item>
          </el-form>
    </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'WithdrawalDetail',

  data () {
    return {
      orderType:1,
      form:{
        value1:'1288210830218302810',
        value2:'6227002818290992111',
        value3:'建设银行',
        value4:'张三',
        value5:'1元',
        value6:'2019-12-23 14:07',
        value7:'提现成功',
      }
    }
  },

  methods: {
 
    
  }
}
</script>

<style lang='scss' scoped>

</style>
